<div class="banner company-banner">
  <div class="banner-item" id="banner_1">
    <h1>Microelectronics Fabrication</h1>
    <span class="red-span">Predictable Success</span>
    <p>with computational models and data-driven processes</p>
  </div>
  <div class="banner-item" id="banner_2">
    <span class="red-span">More than Moore</span>
    <p>Multi-Physics Simulation, Modeling and Design</p>
  </div>
  <div class="banner-item" id="banner_3">
    <span class="red-span">Reach for the sky</span>
    <p>Modeling and Design Enablement for Specialty ICs</p>
  </div>
  <div class="banner-ctrl">
    <span data-id="banner_1" class="white-bgd" (click)="onBannerCtrl($event,'banner_1')"></span>
    <span data-id="banner_2" class="white-bgd" (click)="onBannerCtrl($event,'banner_2')"></span>
    <span data-id="banner_3" class="white-bgd" (click)="onBannerCtrl($event,'banner_3')"></span>
  </div>
</div>
<ul class="icon-list">
  <li (click)="goToRouter('/product/opc')">
    <img src="assets/img/opc-icon.png" alt="OPC">
    <p>OPC</p>
  </li>
  <li (click)="goToRouter('/product/tcad')">
    <img src="assets/img/tCAD-icon.png" alt="TCAD">
    <p>TCAD</p>
  </li>
  <li (click)="goToRouter('/product/basic-ip')">
    <img src="assets/img/IP-icon.png" alt="IP" style="width:38px;">
    <p>基础IP</p>
  </li>
  <li (click)="goToRouter('/product/technology-development')">
    <img src="assets/img/service-icon.png" alt="technology">
    <p>工艺研发</p>
  </li>
</ul>
<div class="container company">
  <h2>公司简介</h2>
  <p>苏州珂晶达电子有限公司成立于2011年，从事科学计算软件开发，集成电路辅助设计(EDA)软件开发和相关的技术服务，以及特种芯片/IP的设计与销售。经过十多年的积累，形成了一支拥有数理基础，EDA软件开发，半导体制造工艺开发，定制化器件设计以及高精度模拟电路设计能力的多元化团队。其核心成员来自国际国内顶尖的科研机构以及Intel/SMIC等业内领先的公司，研发团队半数以上具有研究生以上学历，分别来自物理、微电子、数学、计算机等多种学科背景。</p>
  <p>公司的主要软件产品包括半导体工艺/器件仿真（TCAD）软件；多物理数值仿真软件；系统级可靠性软件；三维网格划分和数据可视化软件等专业软件。同时，公司从事多个系列的特色芯片开发，拥有高压/超宽温度范围/高可靠性/抗辐射等特种领域数字/高精度模拟/功率芯片开发经验，以及M-RAM/R-RAM新型存储器，微显示驱动器，电机驱动器等高度定制化芯片的开发经验。</p>
  <p>凭借在各代半导体制造工艺、半导体器件物理方面深厚的技术积累，公司拥有诸多来自国内微电子行业、高校和研究院的客户，国际客户遍布亚太区域以及欧美，同时在日韩印设有代理机构。2018年以来，公司承担了多个国家级工业软件自主可控项目。</p>
</div>
