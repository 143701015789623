import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-senior',
  templateUrl: './senior.component.html',
  styleUrls: ['./senior.component.css']
})
export class SeniorComponent implements OnInit {
  senior:any;
  curSenior:any;
  showDialog:boolean = false;

  constructor() {
    const self = this;
    self.senior = [
      {
        name:'Ian',
        campus:'华东师范大学',
        job:'软件开发',
        img:'P2',
        description:[
          '作为一名应届生加入珂晶达，感受最多的是公司对新员工的关怀。HR姐姐热情地帮我安排住宿，让我尽快地安顿下来；为了我能够尽快融入项目团队，组长安排了迎新团建；我们有专属的技术导师可以随时答疑解难；项目组会的参与让我对整个项目情况有了全面了解，组长根据我的专业方向安排了一些任务对我进行逐步培养，组长和导师也会经常询问我是否在生活或工作上遇到了困难，公司安排的任务是否感兴趣。',
          '这里是一个成长和学习型的平台，和靠谱的人在靠谱的环境下做靠谱的事，我一定给自己充电追赶公司发展的步伐。'
        ]
      },
      {
        name:'Ellie',
        campus:'苏州大学',
        job:'EDA 应用',
        img:'P1',
        description:[
          '当我们聚在一起，我们就成了亲密的队友。不再是自己一个人孤独地摸索，珂晶达团队在同心协力地追求一个目标。当你遇到困难时，伙伴们会帮助你分析问题、商量解决方案；当你感到迷茫时，阅历丰富的队友们会给你中肯的建议；当你获得成功时，所有的珂晶达同伴都会为你拍手喝彩。',
          '“上下同欲者胜，同舟共济者赢”，珂晶达永远与你一起行进在奋斗路上。'
        ]
      },
      {
        name:'CSC',
        campus:'黑龙江大学',
        job:'EDA 算法',
        img:'P5',
        description:[
          '毕业后从祖国的冰城横跨半个中国来到苏州，有幸成为珂晶达一员，公司很注重新人的培养和发展，我们每年都有很多的培训机会，包括时间和费用上的支持；我们的上班时间比较自由，每个人可以根据自身的作息安排不同的工作时间；老板和同事们之间平等交流、相处起来非常融洽，公司的同事也成了我在苏州最重要的朋友、亲人。',
          '为了跟随公司发展，我选择了在苏州定居，期待能跟公司一起奋斗到老！'
        ]
      },
      {
        name:'Nicole',
        campus:'吉林大学',
        job:'EDA 应用',
        img:'P6',
        description:[
          '珂晶达的发展伴随我个人的成长轨迹，也见证了我人生四部曲：就业、结婚、生子、定居。作为一个职场女性，我们的专业和努力得到平等的认可，迅速成长为技术骨干，“巾帼不让须眉”；作为两个孩子的母亲，公司的儿童游乐室和绘本室提供了便利，让我在加班的时候能兼顾工作和陪伴孩子。',
          '很幸运能成为这个大家庭的一份子，我十分珍惜珂晶达的工作环境和机会，在这里每一天都很开心。'
        ]
      },
      {
        name:'Colin',
        campus:'西安电子科技大学',
        job:'模拟 IC 设计',
        img:'P7',
        description:[
          '我是一个在IC设计行业摸爬滚打10多年的老兵，今年刚加入珂晶达，这里没有一些大公司里的论资排辈和森严的等级制度，珂晶达非常开明和平等、学习氛围浓厚、充满向上的激情。公司产品多元化，有足够多的项目平台和机会，只要你愿意努力，心有多大，舞台就有多大。西安是我喜欢的城市，公司周围餐饮和交通也非常方便，我期待公司的平台和我的专长能相得益彰，互相赋能。'
        ]
      },
      {
        name:'Nick',
        campus:'西安电子科技大学',
        job:'模拟 IC 设计',
        img:'P4',
        description:[
          '来公司两年的时间里，我深刻感受到了这份职业带给我的惊喜，并拥有了无限可能。创始人是学术大牛，同事们也都是相关领域的技术专家，在这里可以和业界的顶尖高手交流，一起做些改变行业的事，引领中国EDA市场的风向标。这个团队每天都保持乐观的态度和好奇心，坚持不懈，不断超越自己。',
          '跟一流的人共事，你会更加优秀！'
        ]
      },
      {
        name:'Luke',
        campus:'大连理工大学',
        job:'软件开发工程师',
        img:'P8',
        description:[
          '作为应届毕业生，我非常幸运的加入了珂晶达大家庭，可以为国家“卡脖子”的半导体行业贡献自己的一份力量。公司同事既厉害又和睦，虽然我是力学专业毕业的，但是他们都很热情的帮助我快速适应工作内容。公司氛围特别融洽，在这里你可以学到很多东西，提升自己的职业技巧。另外，苏州真的是个好城市！'
        ]
      },
    ]
    self.curSenior = {
      name:'',
      description:[]
    }
  }

  ngOnInit(): void {
  }

  showMore(senior:any): void {
    const self = this;
    self.showDialog = true;
    self.curSenior = senior;
  }

}
