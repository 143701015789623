<div class="banner products-banner">
  <span class="red-span">Products</span>
</div>
<div class="nav">
  <div class="nav-item" [ngClass]="{'nav-highlight':curNav == 'opc'}" (click)="changeNav('opc')">OPC</div>
  <div class="nav-item" [ngClass]="{'nav-highlight':curNav == 'tcad'}" (click)="changeNav('tcad')">TCAD</div>
  <div class="nav-item" [ngClass]="{'nav-highlight':curNav == 'ip'}" (click)="changeNav('ip')">基础IP</div>
  <div class="nav-item" [ngClass]="{'nav-highlight':curNav == 'technology'}" (click)="changeNav('technology')">工艺研发</div>
</div>
<div class="container products-container" *ngIf="curNav == 'opc'">
  <div class="product-item product2" (click)="goToRouter('/product/moyee')">
    <h3>MoYee</h3>
    <p>OPC建模工具</p>
  </div>
  <div class="product-item product5" (click)="goToRouter('/product/mozen')">
    <h3>MoZen</h3>
    <p>OPC修正工具</p>
  </div>
  <div class="product-item product3" (click)="goToRouter('/product/movis')">
    <h3>MoVis</h3>
    <p>OPC后处理工具</p>
  </div>
  <!-- <div class="product-item product1" (click)="goToRouter('/product/mover')">
    <h3>Mover</h3>
  </div> -->
</div>
<div class="container products-container" *ngIf="curNav == 'tcad'">
  <div class="product-item product5" (click)="goToRouter('/product/genes')">
    <h3>Genes</h3>
    <p>二维工艺仿真软件</p>
  </div>
  <div class="product-item product1" (click)="goToRouter('/product/genius')">
    <h3>Genius</h3>
    <p>三维并行器件仿真软件</p>
  </div>
  <div class="product-item product3" (click)="goToRouter('/product/gds2mesh')">
    <h3>GDs2Mesh</h3>
    <p>三维 TCAD 模型构建工具</p>
  </div>
  <div class="product-item product2" (click)="goToRouter('/product/visual-tcad')">
    <h3>VisualTCAD</h3>
    <p>器件仿真图形用户界面</p>
  </div>
  <div class="product-item product4" (click)="goToRouter('/product/visual-fab')">
    <h3>VisualFab</h3>
    <p>工艺实验管理平台</p>
  </div>
</div>
<div class="container products-container" *ngIf="curNav == 'ip'">
  <app-basic-ip></app-basic-ip>
</div>
<div class="container products-container" *ngIf="curNav == 'technology'">
  <app-technology-development></app-technology-development>
</div>
