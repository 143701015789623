<div class="banner jobs-banner"></div>
<div class="nav">
  <div class="nav-item" [ngClass]="{'nav-highlight':curNav == '苏州'}" (click)="changeNav('苏州')">苏州</div>
  <div class="nav-item" [ngClass]="{'nav-highlight':curNav == '上海'}" (click)="changeNav('上海')">上海</div>
  <div class="nav-item" [ngClass]="{'nav-highlight':curNav == '西安'}" (click)="changeNav('西安')">西安</div>
</div>
<!-- <div class="title">
  <h3>Join Us！</h3>
  <p>Create Tomorrow's EDA Technology！</p>
</div> -->
<div class="search">
  <input type="text" [(ngModel)]="searchValue" placeholder="职位关键字" (keydown)="search($event);">
  <button type="button" name="button" (click)="search($event);">
    <img src="assets/img/search-icon.png" alt="">
    <span>搜索</span>
  </button>
</div>
<div class="jobs-item empty" *ngIf="showEmpty">
  <h2>未找到对应数据</h2>
</div>
<ngContainer *ngIf="!showEmpty">
  <ngContainer *ngFor="let job of jobs">
    <div class="jobs-item" *ngIf="job.info.city == curNav" (click)="goToRouter(job)">
      <div class="job-title">
        <h3>
          <span class="job-name">{{job.info.name}}</span>
        </h3>
        <span class="job-salary">{{job.info.salary}}</span>
      </div>
      <div class="job-info">
        <span>{{job.info.city}}-{{job.info.area}}</span>
        <span>{{job.info.experience}}年经验</span>
        <span>{{job.info.education}}</span>
        <!-- <span>招{{job.info.number}}人</span> -->
        <!-- <span>{{job.info.time}}发布</span> -->
      </div>
      <!-- <div class="job-benefits">
        <span *ngFor="let benefit of job.benefits">{{benefit}}</span>
      </div> -->
    </div>
  </ngContainer>
</ngContainer>
